<template>
    <div class="purchase-content">
        <el-breadcrumb separator="/" class="student-breadcrumb">
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>在售商品管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page-title">商品管理</div>
        <div class="goodsPurchase-container">
            <div class="header-btn flex justify-end btn-center-group">
                <div v-if="isExamOrTrain" class="end-number" :class="endNumber === 0 ? 'disabled' : ''">商品结算剩余次数：<span>{{endNumber}}</span></div>
                <el-button class="btn-red-line" @click="commoditySettlement" :disabled="endNumber <= 0">商品结算</el-button>
                <el-button class="btn-red" @click="addNewGoods">添加新商品</el-button>
            </div>
            <el-table :data="goodsList" style="width: 100%; margin-top: 20px" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#666', background: '#F5F5F5'}"
                      :cell-style="{fontSize: '14px',color: '#333'}">
<!--                <el-table-column type="selection" align="center" width="50"></el-table-column>-->
                <el-table-column label="商品信息" min-width="200">
                    <template slot-scope="scope">
                        <div  class="goods-wrapper" @click="lookGoodsDetails(scope.row)">
                            <p class="category">所属类目：{{ scope.row.goods_class_name }}</p>
                            <div class="goods-box">
                                <div class="goods-cover">
                                    <img :src="scope.row.goods_img ? scope.row.goods_img : require('../../../../assets/images/user_img.png')" alt="">
                                </div>
                                <div class="goods-right">
                                    <div class="text-overflow-2" :title="scope.row.goods_title">{{ scope.row.goods_title }}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="market_price" align="center" label="销售价"></el-table-column>
                <el-table-column prop="total_repertory" align="center" label="总库存"></el-table-column>
                <el-table-column prop="click_rate" align="center" label="点击率">
                    <template slot-scope="scope">
                        {{scope.row.click_rate}}%
                    </template>
                </el-table-column>
                <el-table-column prop="conversion_rate" align="center" label="转化率">
                    <template slot-scope="scope">
                        {{scope.row.conversion_rate}}%
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" align="center" label="上架时间" width="160px"></el-table-column>
                <template slot="empty">
                    <div class="null-data">
                        <div style="display: flex;flex-direction: column;align-items: center;">
                            <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                            <span>暂无商品</span>
                        </div>
                    </div>
                </template>
            </el-table>
        </div>
<!--        <el-dialog title="添加商品采购" :visible.sync="addDialog" width="420px" @close="resetForm()" :close-on-click-modal="false">-->
<!--            <el-form :model="addForm" ref="addForm" :rules="addRules" label-position="right" label-width="100px">-->
<!--                <el-form-item label="采购商品" prop="id">-->
<!--                    <el-select v-model="addForm.id" placeholder="请选择商品" @change="toggleGoods" clearable style="width: 100%">-->
<!--                        <el-option v-for="supplyItem in supplyList" :key="`supplyList_${supplyItem.id}`" :label="supplyItem.goods_title" :value="supplyItem.id"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="采购数量" prop="brand_num">-->
<!--                    <el-input v-model.number="addForm.brand_num" placeholder="请输入采购数量"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="单价" prop="unit_price">-->
<!--                    <el-input v-model="addForm.unit_price"  placeholder="请输入单价"></el-input>-->
<!--                </el-form-item>-->
<!--            </el-form>-->
<!--            <div slot="footer" class="dialog-footer">-->
<!--                <el-button size="medium" @click="addDialog = false">取 消</el-button>-->
<!--                <el-button size="medium" class="btn-red" @click="addToForm('addForm')">确 定</el-button>-->
<!--            </div>-->
<!--        </el-dialog>-->
    </div>
</template>

<script>
    import {tranPurchaseStuSupplyInfo,tranPurchaseGoodsManager,tranPurchaseGoodsClose} from '@/utils/apis'
    export default {
        name: "GoodsManage",
        data() {
            return {
                goodsList: [],
                // S 添加弹窗
                supplyList:[],
                dialogTitle: '',
                addDialog: false,
                distinguishBtn: '',
                addForm: {
                    id:'',
                    goods_id:'',
                    brand_type:'',
                    goods_title: '', // 商品名1
                    brand_num: '', // 数量1
                    unit_price: '', // 单价1
                },
                addRules: {
                    goods_id: [
                        { required: true, message: '请选择商品', trigger:['blur','change' ]},
                    ],
                    brand_num: [
                        { required: true, type: 'number', message: '请输入采购数量', trigger: 'blur' },
                    ],
                    unit_price: [
                        { required: true, message: '请输入单价', trigger: 'blur' },
                    ],
                },
                currentRow: null,
                // E 添加弹窗
                goodInfoDialog: false,
                goodDetail: null,
                leftCount: null,
                moduleId:Number(this.$route.query.moduleId) || null,
                endNumber: null,
                isExamOrTrain: localStorage.getItem('examId') ? localStorage.getItem('examId') : null
            }
        },
        mounted() {
            this.getSupplyList()
            this.getLibraryList()
        },
        methods: {
            lookGoodsDetails(item) {
                if(!item.is_balance) return;
                let url = this.$router.resolve({ path:'/goods/purchase/detail', query: { id: item.goods_id }});
                window.open(url.href);
            },
            // 获取供应列表
            getSupplyList() {
                let params = {
                    paging:0,
                }
                if (this.$route.query.op_id) {
                    params.op_id = this.$route.query.op_id;
                }
                if (this.$route.query.course_id) {
                    params.course_id = this.$route.query.course_id;
                }
                tranPurchaseStuSupplyInfo(params).then((res) => {
                    this.supplyList = res.data.list
                })
            },
            // 获取已采购列表
            getLibraryList() {
                let params ={}
                if (this.$route.query.op_id) {
                    params.op_id = this.$route.query.op_id;
                }
                if (this.$route.query.course_id) {
                    params.course_id = this.$route.query.course_id;
                }
                tranPurchaseGoodsManager(params).then((res) => {
                    this.goodsList = res.data.list;
                    this.endNumber = res.data.residue_num;
                })
            },
            // 添加新商品
            addNewGoods() {
                if (this.$route.query.op_id){
                    this.$router.push({
                        path:'/student/procurement/index',
                        query:{
                            op_id:this.$route.query.op_id,//训练id
                            course_id:this.$route.query.course_id,//训练id
                            moduleId:this.moduleId,
                        }
                    })
                }else {
                    this.$router.push({
                        path:'/student/procurement/index',
                        query:{
                            moduleId:this.moduleId,
                        }
                    })
                }
            },
            // toggleGoods(val){
            //     let obj = {};
            //     obj = this.supplyList.find((item) => {
            //         return item.id === val
            //     })
            //     this.addForm.brand_type = obj.brand_type
            //     this.addForm.goods_title = obj.goods_title
            //     this.addForm.unit_price = Number(obj.market_price)
            // },
            // 添加弹窗的确定
            // addToForm(formName) {
            //     this.$refs[formName].validate((valid) => {
            //         if (valid) {
            //             if (this.goodsList.length >=5){
            //                 return this.$message.warning('只能采购五款商品')
            //             }
            //             for(let i = 0;i < this.goodsList.length;i++){
            //                 if(this.goodsList[i].goods_id===this.addForm.id){
            //                     return this.$message.warning('商品已存在')
            //                 }
            //             }
            //             let params = {
            //                 brandList:[],
            //             }
            //             let obj = {
            //                 goods_id:this.addForm.id,
            //                 brand_type: this.addForm.brand_type,
            //                 goods_title: this.addForm.goods_title,
            //                 brand_num: this.addForm.brand_num,
            //                 unit_price: this.addForm.unit_price,
            //             }
            //             params.brandList.push(obj)
            //             if (this.$route.query.op_id) {
            //                 params.op_id = this.$route.query.op_id;
            //             }
            //             if (this.$route.query.course_id) {
            //                 params.course_id = this.$route.query.course_id;
            //             }
            //             tranPurchaseStuGoodsPurchase(params).then((res) => {
            //                 this.addDialog = false
            //                 this.getLibraryList()
            //                 this.$message.success(res.msg)
            //             })
            //         } else {
            //             console.log('error submit!!');
            //             return false;
            //         }
            //     });
            // },
            //商品结算
            commoditySettlement(){
                this.$confirm('是否确定要结算商品', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning',
                }).then(() => {
                    let params ={}
                    if (this.$route.query.op_id) {
                        params.op_id = this.$route.query.op_id;
                    }
                    if (this.$route.query.course_id) {
                        params.course_id = this.$route.query.course_id;
                    }
                    tranPurchaseGoodsClose(params).then((res) => {
                        this.$message.success(res.msg)
                        this.getLibraryList()
                    })
                }).catch(() => {
                    this.$message.info('已取消结算商品')
                });
            },
            // 重置表单
            resetForm() {
                this.addForm = {
                    goods_title: '', // 商品名
                    brand_num: '', // 数量
                    unit_price: '', // 单价
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .purchase-content{
        padding: 20px 30px 20px 40px;
        box-sizing: border-box;
    }
    ::v-deep p {
        margin: 0;
    }
    .page-title {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 20px;
        min-height: calc(100vh - 194px);
        /*height: calc(100vh - 189px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .btn-center-group {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .goods-wrapper {
        .category {
            line-height: 1;
            margin-bottom: 10px;
        }
        .goods-box {
            display: flex;
            cursor: pointer;
            .goods-cover {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                border: 1px solid #eee;
                box-sizing: border-box;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-right {
                width: 1%;
                flex: 1;
                .goods-title {
                    color: #333;
                    line-height: 18px;
                    transition: all .3s;
                    &:hover {
                        color: #FD4446;
                    }
                }

            }
        }
    }
    ::v-deep .goodInfo-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #fd4446;
        }
        .el-dialog__title {
            color: #fd4446;
        }
        .el-dialog__body {
            padding: 0 0 30px;
        }
        .goodInfo-content {
            .info-item {
                .item-title {
                    background: #eee;
                    line-height: 1;
                    height: 60px;
                    font-size: 18px;
                    font-weight: 500;
                    padding-left: 36px;
                    color: #333;
                    position: relative;
                    display: flex;
                    align-items: center;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 20px;
                        top: 20px;
                        height: 18px;
                        width: 3px;
                        background: #333;
                    }
                }
                .item-content {
                    padding: 20px;
                    .attr-list {
                        .item {
                            margin-top: 10px;
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    .title-input {
        ::v-deep .el-input__inner {
            padding-right: 50px;
        }
    }
    .operation-pagination {
        padding: 0;
        margin: 30px 0;
        text-align: right;
    }
    .null-data{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 600px;
    }
    .end-number {
        color: #95D476;
        line-height: 38px;
        background: #F0F9EB;
        border-radius: 4px;
        border: 1px solid #E3F4DB;
        margin-right: 10px;
        font-size: 16px;
        padding: 0 16px;
        span {
            font-weight: 500;
        }
        &.disabled {
            color: #9DA0A5;
            background: #F4F4F5;
            border: 1px solid #EEEFF0;
        }
    }
</style>